import { create } from "zustand";
import { persist, devtools } from "zustand/middleware";
import { DeleteData, EditData } from "./authstore";

const CourseStore = set => ({
	data: null,
	status: "",
	allCourse:null,
	getSearchCourse: payload => {
		set(
			state => ({
				mainSearch:
					payload?.search === state?.search
						? payload?.data || payload
						: state?.mainSearch,
				isFound: true,
			}),
			false,
			"getSearchCourse"
		);
	},
	getSearch: payload => {
		set({ data: payload?.data || payload }, false, "getSearch");
	},
	resetCourseSearch: () => {
		set({ search: "", mainSearch: null, isFound: null }, false, "getSearch");
	},
	getCourse: payload => {
		set({ data: payload?.data || payload }, false, "getCourse");
	},
	getAllCourse: payload => {
		set({ allCourse: payload?.data || payload }, false, "getAllCourse");
	},
	addCourse: payload => {
		let data = payload?.data || payload;

		set(
			state => ({
				data: {
					...state?.data,
					docs: state?.data?.docs ? [data, ...state?.data?.docs] : [data],
					totalDocs: state?.data?.totalDocs ? 1 + state?.data?.totalDocs : 1,
					docsTotal: state?.data?.docsTotal ? 1 + state?.data?.docsTotal : 1,
				},
				status: "added",
			}),
			false,
			"addCourse"
		);
	},
	deleteCourse: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: DeleteData(state?.data?.docs, data),
					totalDocs: state?.data?.totalDocs ? state?.data?.totalDocs - 1 : 0,
					docsTotal: state?.data?.docsTotal ? state?.data?.docsTotal - 1 : 0,
				},
				status: "deleted",
			}),
			false,
			"deleteCourse"
		);
	},
	updateCourse: payload => {
		let data = payload?.data || payload;
		set(
			state => ({
				data: {
					...state?.data,
					docs: EditData(state?.data?.docs, data),
				},
				status: "updated",
			}),
			false,
			"editCourse"
		);
	},
	subjectFail: () => {
		set({ status: "", isFound: null });
	},
	logoutCourse: () => {
		set({ status: "", isFound: null, data: null, mainSearch: null, allCourse:null });
	},
});

const useCourseStore = create(
	devtools(
		persist(CourseStore, {
			name: "course",
		})
	)
);

export default useCourseStore;
