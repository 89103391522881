import React from "react";
import { useNavigate } from "react-router-dom";
import { Tablebtn } from "../button";

const CertTable = () => {
  const navigate = useNavigate();
  const Headers = [
      "name",
      "student type",
      "date registered",
      "no. of courses",
      "actions",
    ],
    data = [
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
      {
        name: "John Doe",
        student_type: "company",
        date_registered: "07 May, 2023",
        no_of_courses: "4",
      },
    ];
  return (
    <div>
      <div className=" tw-overflow-x-scroll">
        <table className="tw-min-w-full tw-min-h-full tw-table-auto">
          <thead>
            <tr>
              {Headers?.map((item) => (
                <th className="tw-text-skyblue tw-p-3 tw-font-medium satoshi tw-text-sm tw-capitalize">
                  {item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => (
              <tr
                style={{
                  borderBottom: "1px solid rgba(1, 52, 103, 0.30)",
                }}
                key={i}
              >
                <td className="tableText">{item?.name}</td>
                <td className="tableText tw-capitalize">
                  {item?.student_type}
                </td>
                <td className="tableText">{item?.date_registered}</td>
                <td className="tableText">{item?.no_of_courses}</td>
                <td>
                  <div className="tw-ml-2">
                    <Tablebtn
                      onClick={() => navigate(`/student/${i}`)}
                      text={"View"}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CertTable;
