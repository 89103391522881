/* eslint-disable react/prop-types */
import { createContext } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
import DashboardIcon from "../assets/dashboard.svg";
import CoursesIcon from "../assets/courses.svg";
import StudentIcon from "../assets/students.svg";
import CertificateIcon from "../assets/certificates.svg";
import ReportIcon from "../assets/report.svg";
import { MdNextPlan } from "react-icons/md";
// import GroupIcon from "../assets/group.svg";

export const GlobalState = createContext();

const DataProvider = ({ children }) => {
  const [nav, setNav] = useState(false);
  let handleCapitalize = (word) => {
    let splitter = word.trim().split(" ");
    let firstCap = splitter[0].split("");
    let remaining = splitter.slice(1, splitter.length).join(" ");

    let firstCapOne = firstCap[0].toUpperCase();
    let firstCapTwo = firstCap.slice(1, firstCap.length).join("");

    let joinFirst = `${firstCapOne}${firstCapTwo}`;

    return `${joinFirst} ${remaining}`;
  };

  let numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const toggleNav = () => {
    setNav(!nav);
  };

  let nairaSign = <span className="fontInherit">&#8358;</span>;
  let nairaSignNeutral = "₦";

  let { auth } = useSelector((state) => state);

  let sidebarList = [
    {
      name: "Dashboard",
      icon: DashboardIcon,
      url: "/dashboard",
    },
    {
      name: "Courses",
      icon: CoursesIcon,
      url: "/courses",
    },
    {
      name: "Users",
      icon: StudentIcon,
      url: "/users",
    },
    // {
    //   name: "Groups",
    //   icon: GroupIcon,
    //   url: "/groups",
    // },
    {
      name: "Certificates",
      icon: CertificateIcon,
      url: "/certificates",
    },
    {
      name: "Plan",
      icon: <MdNextPlan />,
      url: "/plan",
      type: "icon",
    },
    {
      name: "Report",
      icon: ReportIcon,
      url: "/reports",
    },
  ];

  const state = {
    handleCapitalize,

    numberWithCommas,

    sidebarList,
    auth,
    nav,
    toggleNav,
    nairaSign,
    nairaSignNeutral,
  };

  return <GlobalState.Provider value={state}>{children}</GlobalState.Provider>;
};

export default DataProvider;
