import React, { useState, useEffect } from "react";
import MainContainer from "../../components/maincontainer";
import MainHeader from "../../components/mainheader";
import { SearchInput } from "../../components/inputs";
import { MainBtn, MainPaginate } from "../../components/button";
import LeaderShipImg from "../../assets/Leadership.png";
import { useNavigate } from "react-router-dom";
import useCourseStore from "../../data/stores/coursestore";
import { apiCall } from "../../data/stores/authstore";
import { ContentWriteup } from "./[id]";

const Courses = () => {
	const navigate = useNavigate();
	const Arr = [
		{
			img: LeaderShipImg,
			title: "Leadership",
			desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
		},
		{
			img: LeaderShipImg,
			title: "Leadership",
			desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
		},
		{
			img: LeaderShipImg,
			title: "Leadership",
			desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
		},
		{
			img: LeaderShipImg,
			title: "Leadership",
			desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
		},
		{
			img: LeaderShipImg,
			title: "Leadership",
			desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
		},
		{
			img: LeaderShipImg,
			title: "Leadership",
			desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
		},
		{
			img: LeaderShipImg,
			title: "Leadership",
			desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
		},
		{
			img: LeaderShipImg,
			title: "Leadership",
			desc: "You can invest in your future by are the studying with us whenever and wherer you want.",
		},
	];

	let [datum, setDatum] = useState({ docs: Arr }),
		{ isFound, mainSearch, data, getCourse } = useCourseStore();
	useEffect(() => {
		if (isFound) setDatum(mainSearch);
		else setDatum(data);
	}, [data, isFound, mainSearch]);

	useEffect(() => {
		apiCall("get", "/api/v1/course", null, getCourse);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	let [range] = useState(10);

	const [itemOffset, setItemOffset] = useState(0);
	const endOffset = itemOffset + range;

	// if (!datum && !data) return <PageLoader />;
	if (!datum) return;

	const currentItems = datum?.docs?.slice(itemOffset, endOffset);
	const pageCount = Math.ceil(datum?.docs?.length / range);

	const handlePageClick = event => {
		const newOffset = (event.selected * range) % datum?.docs?.length;
		setItemOffset(newOffset);
	};
	// console.log({ currentItems });

	return (
		<div>
			<MainContainer>
				<MainHeader
					text={"Courses"}
					small={`${data?.totalDocs || ""} Courses`}
				/>
				<div className="tw-w-full tw-bg-white tw-rounded-3xl tw-p-4">
					<div className="tw-flex tw-justify-between tw-items-center">
						<div className="tw-flex tw-gap-4 tw-items-center">
							<h5 className="lg:tw-text-base tw-text-xs tw-text-skyblue tw-font-bold satoshi">
								All Courses
							</h5>
							<SearchInput />
						</div>
						<MainBtn
							onClick={() => navigate("/courses/add-course")}
							text={"Add course"}
						/>
					</div>
					<div className="tw-mt-6 tw-grid lg:tw-grid-cols-4 tw-gap-6">
						{currentItems?.map((item, i) => (
							<CourseCard
								key={i}
								Img={
									item?.image?.url ||
									item?.module?.[0]?.image?.url ||
									item?.module?.[0]?.section?.[0]?.image?.url ||
									Arr?.[i % (Arr?.length - 1)]?.img
								}
								title={item?.title}
								desc={item?.description || item?.desc}
								onClick={() =>
									navigate(`/courses/${item?.title || i}`, { state: item })
								}
							/>
						))}
					</div>
					{pageCount > 1 && (
						<MainPaginate
							pageCount={pageCount}
							handlePageClick={handlePageClick}
						/>
					)}
				</div>
			</MainContainer>
		</div>
	);
};

export const CourseCard = ({ Img, title, desc, onClick }) => {
	return (
		<div
			style={{
				border: "0.5px solid rgba(1, 52, 103, 0.30)",
			}}
			className="tw-h-80 tw-relative tw-bg-white tw-rounded-xl tw-w-full">
			<img src={Img} alt="" className="tw-rounded-t-2xl tw-w-full tw-h-36" />
			<div className="tw-p-4">
				<h5 className="tw-font-medium tw-text-secondary tw-text-base satoshi">
					{title}
				</h5>
				{/* <p className="satoshi tw-text-xs tw-font-normal tw-text-main">{desc}</p> */}
				{ContentWriteup(
					desc,
					"satoshi tw-text-xs tw-font-normal tw-text-main tw-line-clamp-6"
				)}
				<h6
					onClick={onClick}
					className="tw-text-sm tw-absolute tw-bottom-2 tw-font-medium satoshi tw-text-myblue tw-underline tw-cursor-pointer tw-line-clamp-3">
					View
				</h6>
			</div>
		</div>
	);
};

export default Courses;
