import React from "react";

const ParticipantTab = ({ datum }) => {
	// const certs = [
	//   {
	//     name: "John Doe",
	//     status: "in-progress",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "in-progress",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "in-progress",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "in-progress",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "completed",
	//   },
	//   {
	//     name: "John Doe",
	//     status: "locked out",
	//   },
	// ];

	return (
		<div>
			<div className="tw-w-full tw-space-y-4">
				{datum?.partakers?.map((item, i) => (
					<div
						key={i}
						style={{
							border: "0.5px solid rgba(37, 37, 37, 0.50)",
						}}
						className="tw-px-4 tw-w-full tw-cursor-pointer tw-rounded-lg">
						<div className="tw-h-12 tw-flex tw-justify-between tw-items-center tw-w-full">
							<small className="tw-text-sm tw-font-medium tw-text-main satoshi">
								{item?.lastName} {item?.firstName}
							</small>
							<small
								className={`tw-text-xs tw-font-medium tw-capitalize ${
									item?.progress?.status === "in-progress"
										? "tw-text-secondary"
										: item?.progress?.status === "completed"
										? "tw-text-[#0B6C25]"
										: "tw-text-[#E34033]"
								} satoshi`}>
								{item?.progress?.status}
							</small>
						</div>
					</div>
				))}
			</div>
		</div>
	);
};

export default ParticipantTab;
